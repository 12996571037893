
<div>
  <button v-if="isPM" class="btn" @click="addItem">Add Customer</button>
  <br />
  <br />
  <div class="card">
    <div class="content">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
          <label>Status</label>
          <select-field
            :options="[
              { name: 'Active', value: 'A' },
              { name: 'No Used', value: 'I' },
              { name: 'Inactive', value: '%' }
            ]"
            v-model="status"
            :option-display-keys="['name']"
            :option-val="'value'"
            :allow-clear="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 search-input-group">
          <input type="text" :class="['form-control', 'search-all', { 'not-empty': searchKeyword }]" v-model="searchKeyword" placeholder="Search customers..." @keyup.enter="() => doSearch(false)" />
          <div class="search-buttons">
            <button class="btn btn-sm btn-blue" @click="() => doSearch(false)">Search Filtered</button>
            <button class="btn btn-sm" @click="() => clearFilters()">Search All</button>
            <button class="btn btn-sm btn-danger" @click="() => clearFilters(true)">Reset</button>
          </div>
        </div>
      </div>
      <bh-table :table-settings="tableSettings" @edit-item="editItem" />
    </div>
  </div>
  <!-- <button class="btn" @click="addItem">Add Customer</button> -->
  <waiting-spinner />
</div>
