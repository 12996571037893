<template>
  <div :class="[{ 'additional-styles': additionalStyles }]">
    <div
      :style="{ overflowY: 'auto', minHeight: '50px', maxHeight: maxTableHeight }"
      :class="['show-scrollbars', { 'table-condensed': tableCondensed }, { 'table-striped': tableStriped }, { 'table-bordered': tableBordered }]"
      :id="tableId"
    ></div>
  </div>
</template>

<script>
import moment from 'moment'
// used in tabulator when dateformatter is involved
window.moment = moment
import Tabulator from 'tabulator-tables'
import sanitizeHtml from 'sanitize-html'
export default {
  name: 'TabulatorTable',

  data() {
    return {
      tableId: '',
      table: null,
      lastEditLocation: {
        row: 0,
        col: null
      }
    }
  },

  props: {
    tableData: {
      type: Array,
      required: true
    },
    tableColumns: {
      type: Array,
      required: true
    },
    allowNewRow: {
      type: Boolean,
      default: false
    },
    inlineEditRowValidate: {
      type: Array,
      default: function () {
        return []
      }
    },
    tableHeight: {
      type: [Number, String, Object],
      default: '100%'
    },
    maxTableHeight: {
      type: [String, Number],
      default: '100%'
    },
    tableFit: {
      type: String,
      default: 'fitData'
    },
    tableCondensed: {
      type: Boolean,
      default: false
    },
    tableStriped: {
      type: Boolean,
      default: false
    },
    tableBordered: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: [Boolean, Number],
      default: false
    },
    selectableRollingSelection: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default: '(empty)'
    },
    initialSort: {
      type: Array,
      default: function () {
        return []
      }
    },
    rowFormatter: {
      type: Function,
      default: function () {}
    },
    downloadDataFormatter: {
      type: Function,
      default: function () {}
    },
    responsiveLayout: {
      type: [String, Number, Boolean],
      default: ''
    },
    dataTree: {
      type: Boolean,
      default: false
    },
    headerVisible: {
      type: Boolean,
      default: true
    },
    dataTreeStartExpanded: {
      type: Boolean,
      default: true
    },
    rowHeader: {
      type: Object,
      default: function () {
        return {}
      }
    },
    frozenRows: {
      type: Number,
      default: 0
    },
    additionalStyles: {
      type: Boolean,
      default: false
    },
    movableRows: {
      type: Boolean,
      default: false
    },
    groupBy: {
      type: [Boolean, String],
      default: false
    },
    groupHeader: {
      type: Function,
      default: function () {}
    }
  },

  methods: {
    updateCol(id, col, val, rowData) {
      id = id === 'new' ? 0 : id
      this.$emit('updateCol', { id, col, val, rowData })
    },

    setTable() {
      let tableCols = [...this.tableColumns]
      if (this.selectable && typeof this.selectable === 'boolean') {
        tableCols.unshift({ formatter: 'rowSelection', titleFormatter: 'rowSelection', align: 'center', headerSort: false, frozen: true, cssClass: 'xselect-col', width: 50 })
      } else {
        let index = tableCols.findIndex(itm => {
          return itm.formatter === 'rowSelection'
        })
        if (index !== -1) {
          tableCols.splice(index, 1)
        }
      }

      let _this = this
      this.table = new Tabulator('#' + this.tableId, {
        movableRows: this.movableRows,
        height: this.tableHeight,
        virtualDom: true,
        reactiveData: true,
        data: this.formatTableData(this.tableData),
        layout: this.tableFit,
        columns: tableCols,
        headerVisible: this.headerVisible,
        cellEdited: data => {
          this.editColumn(data)
        },
        cellClick: (e, cell) => {
          this.cellClick(e, cell)
        },
        dataSorted: (sorters, rows) => {
          if (this.allowNewRow) {
            this.removeEmptyRows().then(() => {
              //  this.checkAddRow();
            })
          }
        },
        rowSelected: (row, data) => {
          this.$emit('rowSelected', row.getData())
        },
        rowDeselected: (row, data) => {
          this.$emit('rowDeselected', row.getData())
        },
        renderComplete: data => {
          // causes table to scroll to the right for some reason
          // this.scrollToLastPosition()
        },
        tableBuilt: () => this.$emit('tableBuilt'),
        rowMoved: function (row) {
          _this.$emit('rowMoved', _this.table.getData())
        },
        placeholder: this.emptyText,
        selectable: this.selectable,
        // selectableRangeMode: 'click', // seems to cause more confusing behavior with deselecting
        selectableRollingSelection: this.selectableRollingSelection,
        initialSort: this.initialSort,
        rowFormatter: this.rowFormatter,
        responsiveLayout: this.responsiveLayout,
        keybindings: false,
        dataTree: this.dataTree,
        dataTreeStartExpanded: this.dataTreeStartExpanded,
        groupBy: this.groupBy,
        groupHeader: this.groupHeader
      })
    },

    addRow(data) {
      console.log(this.table, this.allowNewRow)

      if (this.table && this.allowNewRow) {
        console.log(data)
        if (data && data.id) {
          console.log(data)
          this.table.addRow(data)
          return
        }
        return

        // this.table.addRow({id:'new'}, false);

        // Stuff to prefill new row cols
        let firstColField = this.tableColumns.filter(itm => {
          return itm.visible !== false && itm.sorter === 'string'
        })

        if (firstColField) {
          firstColField = firstColField[0].field
          this.table.addRow({ id: 'new', [firstColField]: '' }, false)
        } else {
          this.table.addRow({ id: 'new' }, false)
        }
      }
    },

    getSelectedData() {
      return this.table.getSelectedData()
    },

    cellClick(e, cell) {
      const rowData = cell.getData()
      const field = cell.getField()
      const value = cell.getValue()
      const rowIndex = cell.getRow().getIndex()
      let obj = {
        id: rowData ? rowData.id : null,
        field,
        value,
        rowIndex,
        cell
      }
      this.$emit('colClick', { cell: obj, row: rowData })
    },

    editColumn(data) {
      const rowData = data.getData()
      let id = rowData ? rowData.id : null
      const field = data.getField()
      const value = data.getValue()

      for (var i = 0; i < this.inlineEditRowValidate.length; i++) {
        if (field === this.inlineEditRowValidate[i]) {
          if (!value) {
            this.$snack.open('Column cannot be empty', 'warning')
            return
          }
        }
      }

      this.lastEditLocation = {
        row: id,
        col: field
      }

      if (!id) {
        this.$snack.open('Problem updating data, please reload to try again.', 'warning')
        return
      }
      this.updateCol(id, field, value, rowData)
    },

    updateTable() {
      if (this.table) {
        this.lastEditLocation = {}
        this.table.setData(this.tableData)
        this.table.setSort(this.initialSort)
        this.table.element.scrollLeft = 0
      }
    },

    checkAddRow() {
      if (this.table && this.allowNewRow) {
        let pos = this.table.searchRows('id', '=', 'new')
        if (!pos.length) {
          // this.addRow();
          this.table.addRow({ id: 'new' }, false)
        }
      }
    },

    removeEmptyRows() {
      return new Promise((resolve, reject) => {
        if (this.table) {
          let pos = this.table.searchRows('id', '=', 'new')
          if (pos.length) {
            this.table
              .deleteRow('new')
              .then(() => {
                resolve()
              })
              .catch(e => {
                reject()
              })
          } else {
            resolve()
          }
        }
        resolve()
      })
    },

    scrollToLastPosition() {
      // scroll to last cell edit position
      if (this.table && this.lastEditLocation.col && this.lastEditLocation.row) {
        let row = this.table.getRow(this.lastEditLocation.row)
        if (row) {
          this.table.scrollToRow(this.lastEditLocation.row, 'middle').then(() => {
            this.table.scrollToColumn(this.lastEditLocation.col, 'left')
          })
        }
      }
    },

    destroy() {
      if (this.table) {
        this.table.clearData()
      }
    },

    reloadTable() {
      this.lastEditLocation = {}
      this.destroy()
      this.setTable()
    },

    setFilters(filters = []) {
      const filtersArray = JSON.parse(JSON.stringify(filters))
      this.table.setFilter(filtersArray)
    },

    removeFilter(field) {
      const currentFilter = this.table.getFilters().find(e => e.field === field)
      if (currentFilter) {
        this.table.removeFilter(currentFilter.field, currentFilter.type, currentFilter.value)
      }
    },

    clearAllFilter() {
      this.table.clearFilter()
    },

    formatTableData(data) {
      return data
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          for (var prop in data[i]) {
            if (data[i].hasOwnProperty(prop)) {
              if (typeof data[i][prop] === 'string') {
                data[i][prop] = sanitizeHtml(data[i][prop], { allowedTags: [] })
              }
            }
          }
        }
        console.log(data)
        return data
      }
    },

    exportCSV(name) {
      this.table.download('csv', `${name}.csv`)
    }
  },

  created() {
    // Save the original console.warn function
    const originalConsoleWarn = console.warn

    // Override console.warn
    console.warn = function (message) {
      // Check if the message is from Tabulator, you might need to adjust the condition based on actual messages
      if (message.includes('Tabulator')) {
        // If it is, do nothing (i.e., don't show it)
      } else {
        // Otherwise, call the original console.warn function
        originalConsoleWarn.apply(console, arguments)
      }
    }

    // Now go on with your tabulator.js code without the nagging warnings

    // When you're done and want to restore peace and order:
    console.warn = originalConsoleWarn

    this.tableId = this.randomCharacterString(10)
  },

  mounted() {
    // error if no wait template in some circumstances
    setTimeout(() => {
      this.setTable()
    }, 500)
  },

  beforeDestroy() {
    this.destroy() // quite necessary
    if (this.table) {
      this.table.destroy()
    }
  },

  watch: {
    tableData() {
      this.updateTable()
    },
    selectable() {
      this.reloadTable()
    },
    tableColumns() {
      this.reloadTable()
    },
    allowNewRow() {}
  }
}
</script>

<style lang="scss">
@import 'src/assets/sass/paper/_variables.scss';
// @import "~tabulator/dist/scss/bootstrap/tabulator_bootstrap4";
// @import "~tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min.css";
@import 'tabulator-tables/dist/css/bootstrap/tabulator_bootstrap.min.css';

.tabulator {
  margin-bottom: 0px;

  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  .tabulator-table {
    // width: max-content;
    width: auto;
  }

  .tabulator-cell.clickable,
  .tabulator-row.clickable {
    cursor: pointer;
  }

  .tabulator-cell {
    white-space: normal !important;
    word-wrap: break-word;
    height: auto !important;
    min-height: 36px !important;
    line-height: 1.5 !important;

    &.editable {
      border: 1px solid;
      border-radius: 5px;
      margin: 2px;
    }
  }

  .tabulator-editing {
    border: none !important;
  }

  @media (min-width: 991px) {
    .tabulator-cell[tabulator-field='edit'] {
      // opacity:0;
    }

    .tabulator-row {
      &:hover {
        [tabulator-field='edit'] {
          opacity: 1;
        }
      }

      &.danger-color {
        background-color: $danger-input-bg;
      }

      &.warning-color {
        background-color: $bg-warning;
      }

      &.success-color {
        background-color: $bg-success;
      }
    }
    .tabulator-col {
      &.select-col {
        text-align: center;
      }
    }
  }

  .tabulator-tableHolder .tabulator-placeholder span {
    color: $dark-gray;
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .tabulator-row.tabulator-selected {
    background-color: #9abcea !important;
  }

  [tabulator-field='name'],
  [tabulator-field='edit'] {
    cursor: pointer;
  }

  [tabulator-field='qty'] {
    padding: 2px 0;
    div:nth-child(4) {
      top: 7px !important;
      font-weight: bold;
    }
  }

  .tabulator-col.tabulator-frozen.no-horo-border {
    border-right: none !important;
    border-left: none !important;
  }

  .tabulator-cell.no-horo-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }

  .tabulator-tableHolder .tabulator-placeholder[tabulator-render-mode='virtual'] {
    position: relative !important;
  }

  .tabulator-col-resize-handle {
    display: none !important;
  }
  .tabulator-row .tabulator-frozen.tabulator-frozen-left {
    border: none;
  }
  // .tabulator-selectable {
  //   border-left: 1px solid #ddd;
  // }
}
</style>

<style lang="scss"></style>
