<template>
  <div>
    <button v-if="isPM" class="btn" @click="addItem">Add Customer</button>
    <br />
    <br />
    <div class="card">
      <div class="content">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-3">
            <label>Status</label>
            <select-field
              :options="[
                { name: 'Active', value: 'A' },
                { name: 'No Used', value: 'I' },
                { name: 'Inactive', value: '%' }
              ]"
              v-model="status"
              :option-display-keys="['name']"
              :option-val="'value'"
              :allow-clear="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 search-input-group">
            <input type="text" :class="['form-control', 'search-all', { 'not-empty': searchKeyword }]" v-model="searchKeyword" placeholder="Search customers..." @keyup.enter="() => doSearch(false)" />
            <div class="search-buttons">
              <button class="btn btn-sm btn-blue" @click="() => doSearch(false)">Search Filtered</button>
              <button class="btn btn-sm" @click="() => clearFilters()">Search All</button>
              <button class="btn btn-sm btn-danger" @click="() => clearFilters(true)">Reset</button>
            </div>
          </div>
        </div>
        <bh-table :table-settings="tableSettings" @edit-item="editItem" />
      </div>
    </div>
    <!-- <button class="btn" @click="addItem">Add Customer</button> -->
    <waiting-spinner />
  </div>
</template>
<script>
import store from 'store'
import { mapGetters } from 'vuex'
import BhTable from 'components/UIComponents/BhTable'
import WaitingSpinner from 'components/UIComponents/WaitingSpinner'
import SelectField from 'components/UIComponents/SelectField'
import debounce from 'lodash/debounce'
import appFuncs from 'appFuncs'

export default {
  components: {
    BhTable,
    SelectField,
    WaitingSpinner
  },

  data() {
    return {
      urls: store.getters.urls,
      status: '',
      searchKeyword: '',
      formVals: {
        quote: ''
      },
      tableSettings: {
        tableData: [],
        fields: {
          Customer_Code: {
            name: 'Customer Code',
            hiddenView: 1
          },
          Name: {
            name: 'Name',
            wide: 1
          },
          Contacts: {
            name: 'Contacts',
            noFilter: 1,
            class: 'narrow'
          },
          Sites: {
            name: 'Sites',
            noFilter: 1,
            class: 'narrow'
          },
          Address_1: {
            name: 'Address 1',
            wide: 1
          },
          Zip_Code: {
            name: 'Postal Code',
            class: 'narrow'
          },
          State: {
            name: 'Province',
            class: 'narrow'
          },
          City: {
            name: 'City'
          },
          Email1: {
            name: 'Email'
          },
          Phone_Number: {
            name: 'Phone Number'
          },
          Status: {
            name: 'Status'
          }
        },
        noItemEdit: false,
        hideSearch: true
      },
      searched_customers: []
    }
  },

  computed: {
    ...mapGetters(['customers', 'customerSettingsAR']),

    sortedContacts() {
      return this.sortedContactsFunc(this.customers)
    },

    isPM() {
      return this.$store.getters.userAuthedData.user_role.indexOf('pm') !== -1
    }
  },

  methods: {
    sortedContactsFunc(customersData) {
      let customers = JSON.parse(JSON.stringify(customersData))

      customers = customers.filter(itm => {
        return itm.Status === 'Active'
      })

      if (customers && customers.length) {
        customers = this.addIds(customers)

        for (var i = customers.length - 1; i >= 0; i--) {
          customers[i]['Contacts'] = {
            title: '<i class="ti-new-window"></i>',
            action: 'showContacts'
          }

          customers[i]['Sites'] = {
            title: '<i class="ti-new-window"></i>',
            action: 'showWOSites'
          }
        }

        customers = customers.sort(function (a, b) {
          // Handle cases where Name might be undefined/null/non-string
          const nameA = typeof a['Name'] === 'string' ? a['Name'].trim() : ''
          const nameB = typeof b['Name'] === 'string' ? b['Name'].trim() : ''

          // Sort empty names to the end
          if (!nameA && nameB) return 1
          if (nameA && !nameB) return -1
          if (!nameA && !nameB) return 0

          return nameA.localeCompare(nameB)
        })

        for (var i = customers.length - 1; i >= 0; i--) {
          customers[i]['Name'] = {
            title: customers[i]['Name'],
            action: 'customerInfoClick'
          }
        }
      }
      return customers
    },

    doSearch(searchAll = false) {
      return new Promise((resolve, reject) => {
        const filters = {
          status: this.status
        }

        this.$bus.$emit('setWaiting', { name: 'searchCustomer', message: 'Getting Data' })

        const data = {
          action: 'customer_search',
          search: this.searchKeyword,
          filters
        }

        appFuncs
          .shRequest(data)
          .then(data => {
            this.tableSettings.tableData = this.sortedContactsFunc(data)
            if (searchAll) {
              this.clearFilters()
            }
            resolve()
          })
          .catch(err => {
            console.error(err)
            reject()
          })
          .finally(() => {
            this.$bus.$emit('stopWaiting', 'searchCustomer')
          })
      })
    },

    debouncedSearch: debounce(function () {
      this.doSearch()
    }, 1000),

    clearFilters(clearSearch = false) {
      if (clearSearch) {
        this.searchKeyword = ''
      }
      this.status = ''
    },

    getData(refresh) {
      this.$bus.$emit('setWaiting', { name: 'getCustomers', message: 'Getting Customers' })
      this.$store
        .dispatch('getCustomers', refresh)
        .then(() => {
          this.tableSettings.tableData = this.sortedContacts
        })
        .finally(() => {
          this.$bus.$emit('stopWaiting', 'getCustomers')
        })
        .catch(err => {
          this.$snack.open(err.message || 'Problem getting customers', 'error')
        })

      this.$store.dispatch('getCustomerSettingsAR').catch(err => {
        this.$snack.open(err.message || 'Problem getting customers', 'error')
      })
    },

    addIds(data) {
      // ids are necessary for BHtable component
      for (var i = data.length - 1; i >= 0; i--) {
        data[i]['id'] = i
      }
      return data
    },

    editItem(id) {
      console.log('edit functionality does not exist for Spectrum Customer data')
      return false
    },

    addItem() {
      this.$bus.$emit('modalOpen', {
        component: 'add-customer',
        size: 'modal-xl',
        name: 'add-customer',
        title: 'Add Customer',
        classes: 'gray-bg',
        componentData: {
          component: 'add-customer'
        }
      })
    },

    showCustomerContacts(customer) {
      this.$bus.$emit('modalOpen', {
        component: 'customer-contacts',
        size: 'modal-xl',
        name: 'customer-contacts',
        title: 'Customer Contacts',
        classes: 'gray-bg',
        componentData: {
          component: 'customer-contacts',
          fields: {
            Account_Customer_Code__c: {
              hiddenView: false
            }
          },
          preFiltered: {
            Account_Customer_Code__c: customer['Customer_Code']
          },
          fieldDefaults: {
            Account_Customer_Code__c: customer['Customer_Code'],
            Company: customer['Name']
          },
          noItemEdit: true,
          hideSearch: true
        }
      })
    },

    showWOSites(customer) {
      this.$Modal({
        parent: this,
        name: 'WOSiteList', // used for closing specific modal programmatically
        size: 'xl', // sm, md, lg, xl
        title: 'Work Order Sites',
        hideClose: false,
        component: () => import('components/Dashboard/JobWo/WOSiteList'),
        props: {
          preFiltered: {
            Ship_To_Customer_Code: customer['Customer_Code']
          },
          componentData: {
            fields: {
              Account_Customer_Code__c: {
                hiddenView: false
              }
            },
            fieldDefaults: {
              Account_Customer_Code__c: customer['Customer_Code'],
              Company: customer['Name']
            },
            noItemEdit: true,
            hideSearch: true
          }
        }
      })

      // this.$bus.$emit('modalOpen', {
      //   component: 'customer-contacts',
      //   size: 'modal-xl',
      //   name: 'customer-contacts',
      //   title: 'Customer Contacts',
      //   classes: 'gray-bg',
      //    componentData: {
      //     component: 'customer-contacts',
      //     fields:{
      //       Account_Customer_Code__c: {
      //         hiddenView:false
      //       }
      //     },
      //     preFiltered: {
      //       Account_Customer_Code__c: customer['Customer_Code']
      //     },
      //     fieldDefaults: {
      //       Account_Customer_Code__c: customer['Customer_Code'],
      //       Company: customer['Name']
      //     },
      //     noItemEdit:true,
      //     hideSearch:true
      //   }
      // });
    }
  },

  mounted() {
    this.getData()

    // update item list on custom change event
    this.$bus.$on('customerUpdate', () => {
      this.tableSettings.tableData = this.sortedContacts
    })

    // update item list on custom change event
    this.$bus.$on('showContacts', obj => {
      this.showCustomerContacts(obj)
    })

    // update item list on custom change event
    this.$bus.$on('showWOSites', obj => {
      this.showWOSites(obj)
    })

    this.$bus.$on('customerAdd', () => {
      this.getData(true)
    })

    this.$bus.$on('customerInfoClick', obj => {
      let element = obj

      this.$Modal({
        parent: this,
        name: 'obj', // used for closing specific modal programmatically
        title: element['Name']['title'],
        size: 'xl', // sm, md, lg, xl
        hideClose: false,
        contentClasses: 'smoke-bg',
        component: () => import('components/Dashboard/Contacts/CustomerShow'),
        props: {
          componentData: {
            fields: {
              Account_Customer_Code__c: {
                hiddenView: false
              }
            },
            fieldDefaults: {
              Account_Customer_Code__c: element['Customer_Code'],
              Company: element['Name']['title']
            },
            noItemEdit: true,
            hideSearch: true,
            element,

            customerSettingsAR:
              this.customerSettingsAR.find(itm => {
                return itm.customer_code == element['Customer_Code']
              }) || {},

            preFiltered: {
              Ship_To_Customer_Code: element['Customer_Code']
            },

            preFilteredCustomers: {
              Account_Customer_Code__c: element['Customer_Code']
            }
          }
        }
      })
    })
  },

  watch: {
    sortedContacts(contacts) {
      if (contacts) {
        this.tableSettings.tableData = contacts
      }
    },

    status() {
      this.debouncedSearch()
    },

    searchKeyword() {
      this.debouncedSearch()
    }
  },

  beforeDestroy() {
    this.tableSettings = {}
    this.contacts = []
    this.$bus.$off('showContacts')
  }
}
</script>

<style lang="scss">
.modal-body {
  padding: 20px !important;
}
</style>

<style lang="scss" scoped>
.search-input-group {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  input {
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .search-buttons {
    display: flex;
    gap: 10px;
    flex: 1;

    @media (max-width: 768px) {
      width: 100%;
    }

    .btn {
      white-space: nowrap;
      padding: 6px 12px;
      flex: 1;
    }
  }
}
</style>
